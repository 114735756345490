import ChatBar from "./_react/participations/components/ChatBar.js";

document.addEventListener('DOMContentLoaded', () => {
    try {
        // const chatBarDOMNode = document.querySelector('#chatbar');
        // if (!chatBarDOMNode) return;

        // ReactDOM.unmountComponentAtNode(chatBarDOMNode);
        // ReactDOM.render(<ChatBar />, chatBarDOMNode)

    } catch (error) {
        console.log("error common react: ", error)
    }
})